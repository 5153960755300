.registration.review {
  & #summary .panel {
    float: left;
    margin-right: 20px;

    & table {
      width: 100%;
    }
  }

  & tr td:last-child {
    text-align: right;
    padding-left: 20px;
  }
}
