#home-slideshow {
  margin-bottom: 20px;

  & .owl-stage-outer {
    border-radius: 4px;
  }
}

.map-container {
  text-align: center;

  & #site-map, & #intl-site-map {
    position: relative;
    max-width: 750px;

    & a {
      position: absolute;
      display: block;
      box-sizing: content-box;

      &:hover {
        border: 2px solid #cc0000;
      }
    }
  }
}
