.checklist {
  & ul {
    padding-left: 15px;

    & li {
      list-style-type: none;

      & input[type=checkbox]:checked + a {
        text-decoration: line-through;
      }
    }
  }
}
