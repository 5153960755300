form.registration.household {
  & .listed-people {
    margin-bottom: 50px;

    & .person {
      font-size: 1.2em;
      float: left;
      margin-left: 10px;
    }
  }

  & #registration_household_correction {
    height: 8em;
  }
}
