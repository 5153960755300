@import "./fonts.css";

$breakpoint: 768px;

form {
  & .form-group {
    & .snug {
      width: auto;
      display: inline-block;
    }

    &.date {
      & select.date {
        width: auto;
        display: inline-block;
      }
    }
  }

  &.narrow, & .narrow {
    @media (min-width: $breakpoint) {
      width: 50%;
    }
  }


  & label.boolean {
    margin-left: 20px;
    font-weight: normal;
  }
}

#main-content-wrapper {
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: $breakpoint) {
    & nav.navbar {
      border-radius: 0;

      & .container-fluid {
        padding-left: 0;

        & .navbar-collapse {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  & > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

footer {
  margin-top: 50px;
  text-align: center;
}

abbr[title="required"] {
  color: #cc0000;
}

.padded-row {
  padding: 10px 0;
}

.dotted {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

.navbar-header {
  & img {
    height: 80px;
    padding: 15px;
  }
}

nav.navbar {
  min-height: 80px;
}

ul.navbar-nav {
  padding: 15px 0;
  font-size: 14px;
  font-weight: 600;
}

#ie-warning a {
  text-decoration: underline;
}
