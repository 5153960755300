#home {
  & .row {
    padding: 10px 0;
  }
}

.icon-link {
  font-size: 1.1em;
  text-align: center;
  display: inline-block;
  padding: 0 20px;

  &:hover {
    text-decoration: none;
  }
}
