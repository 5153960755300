@use "./assigned";
@use "./household";
@use "./personal";
@use "./review";
@use "./services";
@use "./where";

form.registration {
  & .people .well {
    float: left;
    margin-right: 20px;
  }

  & label, & div {
    &.boolean + .help-block {
      margin-left: 20px;
    }
  }

  & label.boolean {
    font-weight: bold;
  }
}
