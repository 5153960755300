body[data-namespace="admin"] {
  & #main-content-wrapper {
    max-width: 90%;
  }
}

table {
  & .snug {
    width: 1px;
    white-space: nowrap;
    text-align: center;
  }
}
