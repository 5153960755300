#home .row {
  padding: 10px 0;
}

.icon-link {
  font-size: 1.1em;
  text-align: center;
  display: inline-block;
  padding: 0 20px;
}
.icon-link:hover {
  text-decoration: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcHMvYWRtaW4vYXBwL3ZpZXdzL2FkbWluL3BhZ2VzL2NzcyIsInNvdXJjZXMiOlsiaW5kZXguc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFOzs7QUFJSjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIjaG9tZSB7XG4gICYgLnJvdyB7XG4gICAgcGFkZGluZzogMTBweCAwO1xuICB9XG59XG5cbi5pY29uLWxpbmsge1xuICBmb250LXNpemU6IDEuMWVtO1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgcGFkZGluZzogMCAyMHB4O1xuXG4gICY6aG92ZXIge1xuICAgIHRleHQtZGVjb3JhdGlvbjogbm9uZTtcbiAgfVxufVxuIl19 */