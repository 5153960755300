.admin {
  &.registrations {
    & table ul {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
    }
  }

  &.registration, &.registration-form {
    & .panel {
      float: left;
      margin-right: 20px;

      & table tr td:last-child {
        text-align: right;
        padding-left: 10px;
      }
    }
  }

  &.registration {
    & #notes .fa-trash {
      padding-left: 5px;
    }

    & #notes, & #logs {
      .summary {
        margin-top: 10px;
      }
    }
  }

  & .updates {
    width: 100%;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 0;
  }

  & .stats {
    padding-bottom: 20px;
  }

  & #email-modal textarea {
    width: 100%;
    height: 300px;
  }

  & .person-summary {
    float: left;
    margin-right: 20px;
  }
}
