$brand-primary: #c65b1d;
$navbar-default-bg: white;
$font-family-sans-serif: "Verdana", Helvetica, Arial, sans-serif;
$font-family-serif: "Open Sans", Arial, Tahoma, sans-serif;
$font-family-base: $font-family-serif;
$font-size-base: 16px;
$font-size-h1: 36px;

@import "bootstrap-sass/assets/stylesheets/bootstrap.scss";
@import "register/app/views/register/steps/css/bootstrap-extends.scss";
