.captioned-image {
  position: relative;
  display: inline-block;
  float: left;
  margin-left: 15px;
  margin-bottom: 15px;
}
.captioned-image:nth-child(3n+1) {
  margin-left: 0;
}
.captioned-image img {
  display: block;
  max-width: 390px;
}
.captioned-image .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.3;
  transition: opacity 200ms ease;
  background-color: #000;
  color: #fff;
  font-size: 2em;
  text-align: center;
  padding: 5px 0;
}
.captioned-image:hover .caption {
  opacity: 0.8;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcHMvaW5mby9hcHAvdmlld3MvaW5mby9zaXRlcy9jc3MiLCJzb3VyY2VzIjpbImluZGV4LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLmNhcHRpb25lZC1pbWFnZSB7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICBmbG9hdDogbGVmdDtcbiAgbWFyZ2luLWxlZnQ6IDE1cHg7XG4gIG1hcmdpbi1ib3R0b206IDE1cHg7XG5cbiAgJjpudGgtY2hpbGQoM24rMSkge1xuICAgIG1hcmdpbi1sZWZ0OiAwO1xuICB9XG5cbiAgJiBpbWcge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIG1heC13aWR0aDogMzkwcHg7XG4gIH1cblxuICAmIC5jYXB0aW9uIHtcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgYm90dG9tOiAwO1xuICAgIGxlZnQ6IDA7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgb3BhY2l0eTogMC4zO1xuICAgIHRyYW5zaXRpb246IG9wYWNpdHkgMjAwbXMgZWFzZTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjMDAwO1xuICAgIGNvbG9yOiAjZmZmO1xuICAgIGZvbnQtc2l6ZTogMmVtO1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICBwYWRkaW5nOiA1cHggMDtcbiAgfVxuXG4gICY6aG92ZXIgLmNhcHRpb24ge1xuICAgIG9wYWNpdHk6IDAuODtcbiAgfVxufVxuIl19 */