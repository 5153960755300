.captioned-image {
  position: relative;
  display: inline-block;
  float: left;
  margin-left: 15px;
  margin-bottom: 15px;

  &:nth-child(3n+1) {
    margin-left: 0;
  }

  & img {
    display: block;
    max-width: 390px;
  }

  & .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0.3;
    transition: opacity 200ms ease;
    background-color: #000;
    color: #fff;
    font-size: 2em;
    text-align: center;
    padding: 5px 0;
  }

  &:hover .caption {
    opacity: 0.8;
  }
}
