.registration-summary {
  float: left;
  margin-left: 20px;
}
.registration-summary .progress {
  margin-top: 20px;
  margin-bottom: 0;
}
.registration-summary ul {
  padding-left: 20px;
}
.registration-summary .pull-left:first-child {
  padding-right: 30px;
}

.summaries {
  margin-left: -20px;
}

form.new-registration .list-group button {
  width: 100%;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcHMvcmVnaXN0ZXIvYXBwL3ZpZXdzL3JlZ2lzdGVyL3JlZ2lzdHJhdGlvbnMvY3NzIiwic291cmNlcyI6WyJpbmRleC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOzs7QUFJSjtFQUNFOzs7QUFHRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLnJlZ2lzdHJhdGlvbi1zdW1tYXJ5IHtcbiAgZmxvYXQ6IGxlZnQ7XG4gIG1hcmdpbi1sZWZ0OiAyMHB4O1xuXG4gICYgLnByb2dyZXNzIHtcbiAgICBtYXJnaW4tdG9wOiAyMHB4O1xuICAgIG1hcmdpbi1ib3R0b206IDA7XG4gIH1cblxuICAmIHVsIHtcbiAgICBwYWRkaW5nLWxlZnQ6IDIwcHg7XG4gIH1cblxuICAmIC5wdWxsLWxlZnQ6Zmlyc3QtY2hpbGQge1xuICAgIHBhZGRpbmctcmlnaHQ6IDMwcHg7XG4gIH1cbn1cblxuLnN1bW1hcmllcyB7XG4gIG1hcmdpbi1sZWZ0OiAtMjBweDtcbn1cblxuZm9ybS5uZXctcmVnaXN0cmF0aW9uIC5saXN0LWdyb3VwIGJ1dHRvbiB7XG4gIHdpZHRoOiAxMDAlO1xufVxuIl19 */