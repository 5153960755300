form.registration {
  & .people {
    @extend .clearfix;
  }

  &.assigned input[type="radio"], &.where input[type="radio"], &.household input[type="checkbox"] {
    display: none;

    & + label {
      @extend .btn;
    }
  }

  &.assigned input[type="radio"], &.where input[type="radio"] {
    &:not(:checked) + label {
      @extend .btn-default;

      & .fa-circle-dot {
        display: none;
      }
    }
  }

  &.assigned input[type="radio"] {
    &:checked + label {
      &.good {
        @extend .btn-success;
      }

      &.bad {
        @extend .btn-danger;
      }

      & .fa-circle {
        display: none;
      }
    }
  }

  &.where input[type="radio"] {
    &:checked + label {
      @extend .btn-success;

      & .fa-circle {
        display: none;
      }
    }
  }

  &.household input[type="checkbox"] {
    &:not(:checked) + label {
      @extend .btn-danger;

      & .fa-square-check {
        display: none;
      }
    }

    &:checked + label {
      @extend .btn-success;

      & .fa-square {
        display: none;
      }
    }

    &:disabled + label {
      @extend .btn, .disabled;
    }
  }
}
