.registration-summary {
  float: left;
  margin-left: 20px;

  & .progress {
    margin-top: 20px;
    margin-bottom: 0;
  }

  & ul {
    padding-left: 20px;
  }

  & .pull-left:first-child {
    padding-right: 30px;
  }
}

.summaries {
  margin-left: -20px;
}

form.new-registration .list-group button {
  width: 100%;
}
